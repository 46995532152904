@import "~@ng-select/ng-select/themes/default.theme.css";

/*START cudia style*/

@font-face {
  font-family: "Kanit";
  src: local("Kanit"), url(./assets/font/kanit/Kanit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/font/Roboto/Roboto-Regular.ttf) format("truetype");
}

:root {
  --color-body: rgba(58, 168, 69, 0.2);
  --secondary-color: rgba(35, 117, 171, 0.2);
  --main-border: #82a83a;
  --secondary-border: #2375ab;
  --black-border: black;
  --metal-color: #C4CACE;

  --UI-font: "Kanit", Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  --Data-font: "Roboto", Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

  --main-white: #F4F5F9;
  --main-black: #393A3E;
  --secondary-black: #2F2F37;
  --action-secondary-color: #725BC4;
  --icon-color: #9AF459;
  --save-color: #25C767;
  --danger-color: #D16157;
  --nav-black: #000000;
  --accessory-grey: #ACAFB8;
  --data-grey: #393A3E;
  --soft-grey: #E5E5E5;
  --soft-data-grey: rgba(166, 148, 140, 0.38);
}

h1,
h2,
h3,
h4,
label,
button,
div {
  font-family: var(--UI-font);
}

.mat-tab-label {
  /*  border-left: 0.5px solid #d8d5d5;
  border-right: 0.5px solid #d8d5d5;
  border-top: 0.5px solid #d8d5d5;  */
  padding: 0 12px;
  background-color: rgb(255, 255, 255);
  opacity: 1 !important;

}

.mat-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: scroll;
  min-height: 40vh;
}

/*.mat-tab-nav-bar.mat-primary,
.mat-tab-label:focus,
.mat-tab-label-active,
.mat-tab-group.mat-primary {
    background-color: rgb(246, 190, 143);
    opacity: 1 !important;
     clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%); 
}*/


.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #63a553;
  opacity: 1 !important;
  height: 2px;

}

@media only screen and (min-device-width: 1010px) and (max-device-width: 1260px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .mat-tab-label .mat-tab-label-content {
    font-family: var(--UI-font);
    font-size: 15px !important;
  }
}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1100px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .mat-tab-label .mat-tab-label-content {
    font-family: var(--UI-font);
    font-size: 13px !important;
  }

}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {

  .mat-typography .mat-table {
    font: 400 8px / 15px Roboto, "Helvetica Neue", sans-serif !important;
    letter-spacing: normal;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {}

/*----------- Resize Laptop No standartd interval but manage ----------*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {}


/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .contenitore-tabelle-grafici app-tabella-semplice .col-2.custom-simple-tab-header-granzie.font-bold.margin-top-10.min-height-25.ng-star-inserted {
    font-size: 10px !important;
  }

  .custom-simple-tab-content-garanzie {
    font-size: 18px !important;
  }

  .container-fluid.first-conteiner-top-section {
    padding-right: 300px !important;
  }

  .potenziometri-time {
    margin-left: 18vw !important;
  }

  app-configurazione-business-plan .mat-tab-label-content {
    font-size: 12px !important;
    font-family: var(--UI-font);

  }

  .mat-tab-label-active {
    color: black !important;
    background-color: white;
  }


  app-configurazione-business-plan .mat-tab-label {
    padding: 0 12px;
  }
}

@media only screen and (min-device-width: 1180px) and (max-device-width: 1180px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .potenziometri-time {
    margin-left: 7vw !important;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (max-device-height:768px) and (min-device-height:768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  app-configurazione-business-plan .mat-tab-label-content {
    font-size: 12px !important;

  }

  app-configurazione-business-plan .mat-tab-label {
    padding: 0 12px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */


@media only screen and (min-device-width: 1180px) and (max-device-width: 1180px) and (max-device-height:820px) and (min-device-height:820px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  app-configurazione-business-plan .mat-tab-label-content {
    font-size: 12px !important;

  }

  app-configurazione-business-plan .mat-tab-label {
    padding: 0 12px;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (max-device-height:1024px) and (min-device-height:1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .potenziometri-time {
    margin-left: 13vw !important;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .potenziometri-time {
    margin-left: 13vw !important;
  }
}

div[class="tab-pane fade active show"],
div[class="tab-pane fade show"],
.tab-content>.tab-pane {
  margin: 2vh 0 !important;
  transition: none !important;
}

.nav-pills .nav-link {
  padding: 0 !important;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0);
  max-width: 12px;
  max-height: 10px;
  background-color: var(--secondary-black);
  margin-bottom: 10px;
}

.nav-pills .nav-link.active {
  color: rgba(0, 0, 0, 0);
}


body {
  background-color: var(--main-white) !important;
  overflow-x: hidden;
}

.align-middle.text-center.mx-auto.mt-2.h5 {
  font-family: var(--UI-font), 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--main-white);
}

.border-main-color {
  border: solid !important;
  border-width: 3px !important;
  border-color: var(--main-border) !important;
  border-radius: 30px !important;
}

.border-secondary-color {
  border: solid !important;
  border-width: 3px !important;
  border-color: var(--secondary-border) !important;
  border-radius: 30px !important;
}


.border-black-color {
  border: solid !important;
  border-width: 3px !important;
  border-color: var(--black-border) !important;
  border-radius: 10px !important;
}

/*END cudia style*/

.sfondo {
  /* background: url('/assets/img/sfondo.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  color: black;
  /* text-align: center; */
}

.navbar-custom {
  background-color: transparent;
}

.col-12.padding-left-right-15.pointer.padding-top-bottom-10.font-big.margin-top-20.header-tabella.conto-economico~.col-12.margin-bottom-20.padding-top-bottom-10.padding-left-right-15.borderL.borderR.borderB>.row.margin-0.text-center {
  background-color: var(--soft-data-grey) !important;
}

.col-12.padding-left-right-15.pointer.padding-top-bottom-10.font-big.margin-top-20.header-tabella.conto-economico~.col-12.margin-bottom-20.padding-top-bottom-10.padding-left-right-15.borderL.borderR.borderB {
  background-color: var(--main-white);
}

.benvenuto {
  font-family: Century, Helvetica, Arial;
  border-radius: 20px;
  border-color: #436ab3;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 0px 25px #333333;
  -moz-box-shadow: 0px 0px 25px #333333;
  -webkit-box-shadow: 0px 0px 25px #333333;
  padding: 20px;
  margin-top: 50px;
  text-align: center;
}

.loggato {
  font-family: Century, Helvetica, Arial;
  font-style: italic;
  margin-top: 8px;
  text-align: left;
  color: #11519e;
}

.login {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  opacity: 0.9;
  margin-left: 80px;
  width: 350px;
  box-shadow: 0 6px 12px rgb(134 140 150 / 65%);
  text-align: center;
}

.ombra {
  box-shadow: 1 5px 12px rgb(134 140 150 / 65%);
  border-radius: 5px 5px 5px 5px;
}

.sfondo-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  background-image: -webkit-gradient(linear, to left top, to left bottom, from(#C9DE96), to(#059b5b));
  background-image: -webkit-linear-gradient(to top, #C9DE96, #059b5b);
  background-image: -moz-linear-gradient(to top, #C9DE96, #059b5b);
  background-image: -ms-linear-gradient(to top, #C9DE96, #059b5b);
  background-image: -o-linear-gradient(to top, #C9DE96, #059b5b);
  background-image: linear-gradient(to top, #C9DE96, #059b5b);
  background-color: #059b5b;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.btn-outline-secondary {
  border-color: #ced4da;
  border-radius: 0px 5px 5px 0;
}

/*Riccardo*/
.font-small {
  font-size: 12px;
}

.font-big {
  font-size: 22px;
}

.font-very-big {
  font-size: 30px;
}

.font-titolo {
  font-size: 40px;
}

.font-bold {
  font-weight: bold;
}

.font-black {
  color: black;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-left-right-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.margin-left-right-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-0 {
  margin: 0px !important;
}

.pointer {
  cursor: pointer;
}

.nopadding {
  padding: 0px !important;
}

.nopadding-right {
  padding-right: 0px !important;
}

.nopadding-left {
  padding-left: 1px !important;
}

.padding-top-bottom-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-left-right-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-left-right-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-left-right-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.borderAll {
  border: 2px solid rgba(0, 0, 0, 0.125);
}

.borderR {
  border-right: 2px solid rgba(0, 0, 0, 0.125);
}

.borderL {
  border-left: 2px solid rgba(0, 0, 0, 0.125);
}

.borderT {
  border-top: 2px solid rgba(0, 0, 0, 0.125);
}

.borderB {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.borderBThin {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.borderTThin {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.borderAllBlack {
  border: 1px solid black;
}

.borderRBlack {
  border-right: 1px solid black;
}

.borderLBlack {
  border-left: 1px solid black;
}

.borderTBlack {
  border-top: 1px solid black;
}

.borderBBlack {
  border-bottom: 1px solid black;
}

.min-height-25 {
  min-height: 25px;
}

.full-height {
  height: 100%;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-bottom-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-bottom-center {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-center-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.bg-light-yellow {
  background-color: rgb(255, 217, 0);
}

.bg-green {
  background-color: #00ff40;
}

.bg-red {
  background-color: #e20013;
}


.loading-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1031;
}

@media(max-width: 992px) {
  .hidden-xs-sm-md {
    display: none;
  }

  .contenitore-principale {
    margin: 0 auto !important;
    max-width: 95% !important;
    width: 95% !important;
  }
}

@media(min-width: 992px) {
  .hidden-lg-xl {
    display: none;
  }

  ngb-typeahead-window.dropdown-menu {
    max-height: 140px;
    overflow-y: auto;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.row.margin-0.row-cols-6 .col.borderLBlack.borderRBlack.borderBBlack.min-height-25 {
  font-size: 10px !important;
  line-height: 11px !important;
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

tr.mat-row,
tr.mat-cell {
  min-height: 10px !important;
  max-height: 10px !important;
  height: 10px !important;
}

.mat-tab-body-content {
  overflow-x: hidden;
}

.mat-tab-label,
.mat-tab-label-content,
.mat-tab-link {
  font-size: 17px !important;

}

.label-error {
  color: red
}

.mat-tab-label>div {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
}

#label-valutazione {
  margin-top: 30px;
  margin-left: 30px;
}

table.mat-table.cdk-table.mx-auto.last-table-margin.top-table {
  margin-left: 30px !important;
}


#label-as-is {
  margin-top: 48px !important;
}

.col-12.padding-left-right-15.padding-top-bottom-10.font-big.margin-top-20.header-tabella {
  margin-top: 0 !important;
}

.col-12.nopadding.margin-bottom-20 .row.margin-0>.col-2 {
  margin-top: -10px !important;
}

.tab-pane.fade.show.active.ng-star-inserted .container.ng-star-inserted {
  margin: 0 !important;
}

.mat-expansion-panel-body {
  background-color: rgb(245, 245, 245);
}

.rigaDialog {
  position: absolute;
  top: 87vh;
  left: 11.5vw;
}



td.mat-cell.cdk-cell.cdk-column--1YD.mat-column--1YD,
td.mat-cell.cdk-cell.cdk-column-MT.mat-column-MT,
td.mat-cell.cdk-cell.cdk-column-LT.mat-column-LT,
td.mat-cell.cdk-cell.cdk-column-YTD.mat-column-YTD,
td.mat-cell.cdk-cell.cdk-column-VALORE.mat-column-VALORE,
td.mat-cell.cdk-cell.cdk-column-PRECEDENTE.mat-column-PRECEDENTE,
td.mat-cell.cdk-cell.cdk-column-More-Likely-Case,
td.mat-cell.cdk-cell.cdk-column-Worst-Case,
td.mat-cell.cdk-cell.cdk-column-Best-Case,td.mat-cell.cdk-cell.cdk-column-MLCYTD,
td.mat-cell.cdk-cell.cdk-column-MLCMT,
td.mat-cell.cdk-cell.cdk-column-MLCLT,td.mat-cell.cdk-cell.cdk-column-WCYTD,
td.mat-cell.cdk-cell.cdk-column-WCMT,
td.mat-cell.cdk-cell.cdk-column-WCLT,td.mat-cell.cdk-cell.cdk-column-BCYTD,
td.mat-cell.cdk-cell.cdk-column-BCMT,td.mat-cell.cdk-cell.cdk-column-BCLT {
  font-weight: bold !important;
  font-size: 12px !important;
  color: var(--nav-black) !important;
  text-align: end;
}

td.mat-cell.cdk-cell.cdk-column-INDICI.mat-column-INDICI,
td.mat-cell.cdk-cell.cdk-column--PROB_ml {
  font-size: 12px !important;
  color: var(--nav-black) !important;
}

td.mat-cell.cdk-cell.cdk-column--PROB_ml {
  font-size: 14px !important;
  color: var(--nav-black) !important;
}

th.mat-header-cell.cdk-header-cell.cdk-column-YTD.mat-column-YTD,
th.mat-header-cell.cdk-header-cell.cdk-column--1YD,
th.mat-header-cell.cdk-header-cell.cdk-column-MT,
th.mat-header-cell.cdk-header-cell.cdk-column-LT.mat-column-LT,
th.mat-header-cell.cdk-header-cell.cdk-column-VALORE.mat-column-VALORE,
th.mat-header-cell.cdk-header-cell.cdk-column-PRECEDENTE.mat-column-PRECEDENTE,
th.mat-header-cell.cdk-header-cell.mlc.cdk-column-More-Likely-Case,
th.mat-header-cell.cdk-header-cell.wc.cdk-column-Worst-Case,
th.mat-header-cell.cdk-header-cell.bc.cdk-column-Best-Case,td.mat-cell.cdk-cell.cdk-column-MLCYTD,
td.mat-cell.cdk-cell.cdk-column-MLCMT,
td.mat-cell.cdk-cell.cdk-column-MLCLT,td.mat-cell.cdk-cell.cdk-column-WCYTD,
td.mat-cell.cdk-cell.cdk-column-WCMT,
td.mat-cell.cdk-cell.cdk-column-WCLT,td.mat-cell.cdk-cell.cdk-column-BCYTD,
td.mat-cell.cdk-cell.cdk-column-BCMT,td.mat-cell.cdk-cell.cdk-column-BCLT {
  text-align: end !important;
}

.mat-cell.cdk-cell.cdk-column-YTD.mat-column-YTD {
  font-size: 12px !important;
  font-weight: bold !important;
}

.container-fluid.first-conteiner-top-section {
  padding-right: 200px !important;
}

#label-valutazione {
  width: 400px;
}

#nome-azienda {
  width: 300px;
}

#seconda_sezine div.margin-0.nopadding.row.text-center {
  height: 5px !important;
}

.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(1),
.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(2),
.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(3),
.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(4),
.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(5),
.col-2.borderBBlack.borderLBlack.borderRBlack.min-height-25.font-bold.flex-justify-center.ng-tns-c198-1:nth-child(6),
.col-12.margin-bottom-20.padding-top-bottom-10.padding-left-right-15.borderL.borderR.borderB.ng-tns-c198-2.ng-star-inserted .row.margin-0.text-center.ng-tns-c198-2:nth-child(1) {
  background-color: #FFFFFF !important;
}

.row.margin-0.text-center.rowColor:nth-child(2n+1) {
  background-color: #DDD6D3 !important;
}

.row.margin-0.text-center.rowColor:nth-child(n+1) {
  background-color: #E5E5E5;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#situazioneDebitoria .row.margin-0.text-center.interno {
  background-color: #FFFFFF !important;
}

#conto-economico-table .row.margin-0:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#conto-economico-table .row.margin-0:nth-child(even) {
  background-color: #E5E5E5 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#conto-economico-table .row.margin-0:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;
}

#default-table .row.margin-0:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#default-table .row.margin-0:nth-child(even) {
  background-color: #E5E5E5 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#default-table .row.margin-0:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;
}

#cashflowIndiretto .row.margin-0.text-center:nth-child(even) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#cashflowIndiretto .row.margin-0.text-center:nth-child(odd) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#cashflowIndiretto .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;

}

#cashflowDiretto .row.margin-0.text-center:nth-child(even) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#cashflowDiretto .row.margin-0.text-center:nth-child(odd) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#cashflowDiretto .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;

}

#container-debito-finanziario .row.margin-0.text-center:nth-child(even) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;

}

#container-debito-finanziario .row.margin-0.text-center:nth-child(odd) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#container-debito-finanziario .row.margin-0.text-center:nth-child(2),
#container-debito-finanziario .row.margin-0.text-center:nth-child(6),
#container-debito-finanziario .row.margin-0.text-center:nth-child(10),
#container-debito-finanziario .row.margin-0.text-center:nth-child(14) {
  background-color: #FFFFFF !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#container-debito-finanziario .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;

}

#container-debito-finanziario .row.margin-0.text-center:nth-child(even) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;

}

#container-debito-finanziario .row.margin-0.text-center:nth-child(odd) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#container-debito-finanziario .row.margin-0.text-center:nth-child(2),
#container-debito-finanziario .row.margin-0.text-center:nth-child(6),
#container-debito-finanziario .row.margin-0.text-center:nth-child(10),
#container-debito-finanziario .row.margin-0.text-center:nth-child(14) {
  background-color: #FFFFFF !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#container-debito-finanziario .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;

}

#indiciDiBilancio .row.margin-0.text-center:nth-child(odd) {
  background-color: #FFFFFF !important;
  border: none;
}

#indiciDiBilancio .row.margin-0.strisce:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#indiciDiBilancio .row.margin-0.strisce:nth-child(even) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#indiciDiBilancio .row.margin-0.tab-indicatori:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#indiciDiBilancio .row.margin-0.tab-indicatori:nth-child(even) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#indiciDiBilancio .row.margin-0.text-center:nth-child(2),
#indiciDiBilancio .row.margin-0.text-center:nth-child(6),
#indiciDiBilancio .row.margin-0.text-center:nth-child(14),
#indiciDiBilancio .row.margin-0.text-center:nth-child(17),
#indiciDiBilancio .row.margin-0.text-center:nth-child(24),
#indiciDiBilancio .row.margin-0.text-center:nth-child(28),
#indiciDiBilancio .row.margin-0.text-center:nth-child(34){
  background-color: #FFFFFF !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#indiciDiBilancio .row.margin-0.text-center:nth-child(2) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1),
#indiciDiBilancio .row.margin-0.text-center:nth-child(6) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1),
#indiciDiBilancio .row.margin-0.text-center:nth-child(14) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1),
#indiciDiBilancio .row.margin-0.text-center:nth-child(17) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1),
#indiciDiBilancio .row.margin-0.text-center:nth-child(24) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1),
#indiciDiBilancio .row.margin-0.text-center:nth-child(28) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1), 
#indiciDiBilancio .row.margin-0.text-center:nth-child(34) .col-2.borderBBlack.borderLBlack.min-height-25.font-bold.flex-justify-center:nth-child(1) {
    background-color: var(--accessory-grey) !important;
}



#indiciDiBilancio .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none;
}

#label-probabilità-vslitszioneMCC {
  font-size: 25px;
  margin-top: -32px;
}

#valutazioneMcc .row.margin-0.text-center:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#valutazioneMcc .row.margin-0.text-center:nth-child(even) {
  background-color: #E5E5E5 !important;
  border: none;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#valutazioneMcc .row.margin-0.text-center:nth-child(1) {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}

#valutazioneMcc .row.margin-0.text-center:nth-child(2) {
  background-color: #FFFFFF !important;
  border: none;
}

.lableProbIndiciBilancio {
  font-size: 25px;
  margin-top: -32px;
}



#containerProbIndiciBilancio .row.margin-0.text-center:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#containerProbIndiciBilancio .row.margin-0.text-center:nth-child(even) {
  background-color: #E5E5E5 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.001px;
  border-bottom-width: 0.001px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#containerProbIndiciBilancio .row.margin-0.text-center:nth-child(1) {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}

#containerProbIndiciBilancio .row.margin-0.text-center:nth-child(2) {
  background-color: #FFFFFF !important;
  border: none;
}

#labelAttivo {
  font-size: 25px;
  margin-top: -10px;
}

#labelPassivo {
  font-size: 25px;
  margin-top: -10px;
}

#containerAttivoStatoPatrimoniale .row.margin-0.text-center:nth-child(odd) {
  background-color: #DDD6D3 !important;

}

#containerAttivoStatoPatrimoniale .row.margin-0.text-center:nth-child(even) {
  background-color: #E5E5E5 !important;

}

#containerAttivoStatoPatrimoniale .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none !important;
}

#containerPassivoStatoPatrimoniale .row.margin-0.text-center:nth-child(odd) {
  background-color: #DDD6D3 !important;

}

#containerPassivoStatoPatrimoniale .row.margin-0.text-center:nth-child(even) {
  background-color: #E5E5E5 !important;

}

#containerPassivoStatoPatrimoniale .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none !important;
}

#cashflowIndiretto .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none !important;
}

#cashflowDiretto .row.margin-0.text-center:nth-child(1) {
  background-color: #FFFFFF !important;
  border: none !important;
}

.garanzia-tabbella-semplice .col-12[style="background-color: rgba(172, 175, 184, 0.1); border-radius: 8px;"] {
  margin-top: 4.5%;
  margin-left: -6%;
}

#crisi-impresa-table .row.riga:nth-child(odd),
#cr-table .row.riga:nth-child(odd) {
  background-color: #DDD6D3 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.0005px;
  border-bottom-width: 0.0005px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

#crisi-impresa-table .row.riga:nth-child(even),
#cr-table .row.riga:nth-child(even) {
  background-color: #E5E5E5 !important;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 0.0005px;
  border-bottom-width: 0.0005px;
  border-top-color: #A59597;
  border-bottom-color: #A59597;
}

/* conta 1 dall'ultimo child */
#crisi-impresa-table .row.riga:nth-last-child(1),
#cr-table .row.riga:nth-last-child(1) {
  border-bottom: 1px solid black !important;
}


#crisi-impresa-table .row.legenda,
#cr-table .row.legenda {
  background-color: #FFFFFF !important;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

#crisi-impresa-table .row.intestazione,
#cr-table .row.intestazione {
  background-color: #FFFFFF !important;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.tooltip-inner {
  background-color: rgb(65, 65, 65);
  opacity: 1;
  filter: alpha(opacity=100);

}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(65, 65, 65);
  opacity: 1;
}

.border-horizontal {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.border-right {
  border-right: 1px solid black;
}

.border-left {
  border-left: 1px solid black;
}


.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: rgb(65, 65, 65);
  opacity: 1;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(65, 65, 65);
  opacity: 1;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(65, 65, 65);
  opacity: 1;
}

div#label-as-is,
mat-icon.mat-icon.notranslate.md-80.material-icons.mat-icon-no-color,
mat-icon.mat-icon.notranslate.md-80.material-icons.mat-icon-no-color {
  display: none;
}

#seconda_sezione .custom-simple-tab-content {
  width: 100%;
}

#seconda_sezione #media .row.margin-0 .col-12.min-height-25 {
  font-size: 25px;
}

.warning-control {
  border-color: rgb(241, 141, 25);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='rgb(241, 122, 25)'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* padding-right: calc(1.5em + 0.75rem);
        background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */


#lineeDiBusiness div[tabindex="0"][aria-posinset="1"] {
  background-color: rgba(0, 0, 0, 0) !important;
}

.warning-label {
  color: rgb(241, 122, 25);
  font-size: .875em;
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {}

#lineeDiBusiness div[tabindex="0"][aria-posinset="1"] {
  background-color: rgba(0, 0, 0, 0) !important;
}



div#container-bpconfiguation {
  min-height: 100vh;
}

app-configurazione-business-plan .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination,
app-configurazione-ambiente .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

app-inserimento-ricavi-costi .mat-tab-label-container.sticky-navbar-linee,
app-inserimento-ricavi-costi .mat-tab-label-container.sticky-navbar-linee {
  transform: translateX(-13px) !important;
  width: 94.5% !important;
}

/*.sub-scroll-conf-ambiente {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 45vh;
  max-height: 88vh;
}*/

/* width */
::-webkit-scrollbar {
  width: 15px;
  padding: 0 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3E3E3E;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-black);
}

#save-magazzino {
  padding: 5px 20px 5px 20px !important;
}

#addPaddingInStickyIndicatori .col-12.d-flex.align-items-center>h2 {
  z-index: 4;
}

.distanza-terna-potenziometri {
  margin-top: -6px;
}


.container-fluid.first-conteiner-top-section {
  margin-top: 20px;
}

.navbar {
  height: 40px !important;
}

#label-valutazione {
  margin-bottom: 10px !important;
}

.input-group-append {
  margin: auto !important;
}

.arancione-goalgo {
  color: #ef790b;
}

.verde-goalgo {
  color: #63a553;
}

.colore0 {
  background-color: red !important;
}

.colore1 {
  background-color: blue !important;
}

.colore2 {
  background-color: green !important;
}

.colore3 {
  background-color: violet !important;
}

.colore4 {
  background-color: yellow !important;
}

.colore5 {
  background-color: cyan !important;
}

.colore6 {
  background-color: orange !important;
}